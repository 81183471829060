import React, { useState } from "react";
import { Container, LoadingOverlay } from "@mantine/core";

const StreamlitApp = () => {
    const [isLoading, setIsLoading] = useState(true);

    return (
        <div>
            <Container
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                    width: "auto"
                }}
            >
                <iframe
                    src="https://pricetorent.streamlit.app/?embed=true"
                    title="PriceToRent Dashboard"
                    style={{
                        width: "100%",
                        height: "100%",
                        border: "none",
                        background: "transparent"
                    }}
                    onLoad={() => setIsLoading(false)}
                />
                {isLoading && (
                    <LoadingOverlay
                        visible={isLoading}
                    />
                )}
            </Container >
        </div >
    );
};

export default StreamlitApp;