import { Container, Title, Accordion, createStyles, rem, Anchor } from '@mantine/core';

const useStyles = createStyles((theme) => ({
    wrapper: {
        paddingTop: `calc(${theme.spacing.xl} * 2)`,
        paddingBottom: `calc(${theme.spacing.xl} * 2)`,
        minHeight: 650,
    },

    title: {
        marginBottom: `calc(${theme.spacing.xl} * 1.5)`,
    },

    item: {
        borderRadius: theme.radius.md,
        marginBottom: theme.spacing.lg,
        border: `${rem(1)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
            }`,
    },
}));

export function FaqSimple() {
    const { classes } = useStyles();
    return (
        <Container size="sm" className={classes.wrapper}>
            <Title align="center" className={classes.title}>
                Frequently Asked Questions
            </Title>

            <Accordion variant="separated">

                <Accordion.Item className={classes.item} value="ratio-meaning">
                    <Accordion.Control>What does PriceToRent mean?</Accordion.Control>
                    <Accordion.Panel>PriceToRent is a The price-to-rent ratio compares the
                        cost of buying a property to the cost of renting it.
                        A low ratio means buying is cheaper, while a high ratio means renting is cheaper.
                        It helps people decide whether it's better to buy or rent a property in a specific area.
                    </Accordion.Panel>
                </Accordion.Item>

                <Accordion.Item className={classes.item} value="data-source">
                    <Accordion.Control>Where do you get the data from?</Accordion.Control>
                    <Accordion.Panel>Our data is aggregrated from various data providers. This is updated monthly.</Accordion.Panel>
                </Accordion.Item>

                <Accordion.Item className={classes.item} value="location-support">
                    <Accordion.Control>What locations do you support?</Accordion.Control>
                    <Accordion.Panel>We support locations all across the United States.
                        We have plans to expand to other locations such as Canada next.</Accordion.Panel>
                </Accordion.Item>

                <Accordion.Item className={classes.item} value="cost">
                    <Accordion.Control>Is this free to use?</Accordion.Control>
                    <Accordion.Panel>We are completely free.
                        There are ideas to launch a subscription service in the future but that would be for premium features and insights.
                        The PriceToRent basic features will completely be free.</Accordion.Panel>
                </Accordion.Item>

                <Accordion.Item className={classes.item} value="contact">
                    <Accordion.Control>How do I get in touch with you?</Accordion.Control>
                    <Accordion.Panel><Anchor href="https://twitter.com/PriceToRent" target="_blank" rel="noopener noreferrer">
                        Get in touch with us on Twitter!
                    </Anchor></Accordion.Panel>
                </Accordion.Item>

                <Accordion.Item className={classes.item} value="disclaimer">
                    <Accordion.Control>Is this financial advice?</Accordion.Control>
                    <Accordion.Panel>No. PriceToRent is for informational purposes only and is not financial advice.</Accordion.Panel>
                </Accordion.Item>

            </Accordion>
        </Container>
    );
}