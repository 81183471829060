import { createStyles, Anchor, Group, ActionIcon, rem, Container, Text } from '@mantine/core';
import { IconBrandTwitter } from '@tabler/icons-react';
import { navLinks } from '../../data/pageLinks';
import { Link } from 'react-router-dom';

const useStyles = createStyles((theme) => ({
    footer: {
        marginTop: rem(120),
        borderTop: `${rem(1)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]
            }`,
    },
    afterFooter: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: theme.spacing.xl,
        paddingTop: theme.spacing.xl,
        paddingBottom: theme.spacing.xl,
        borderTop: `1px solid ${theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[2]
            }`,

        [theme.fn.smallerThan("sm")]: {
            flexDirection: "column",
        },
    },
    inner: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: `${theme.spacing.md} ${theme.spacing.md}`,

        [theme.fn.smallerThan('sm')]: {
            flexDirection: 'column',
        },
    },

    links: {
        [theme.fn.smallerThan('sm')]: {
            marginTop: theme.spacing.lg,
            marginBottom: theme.spacing.sm,
        },
    },

    link: {
        display: "block",
        color:
            theme.colorScheme === "dark"
                ? theme.colors.dark[1]
                : theme.colors.gray[6],
        fontSize: theme.fontSizes.sm,
        paddingTop: 3,
        paddingBottom: 3,

        "&:hover": {
            textDecoration: "underline",
        },
    },
}));

interface FooterCenteredProps {
    links: { link: string; label: string }[];
}

export function FooterCentered({ links }: FooterCenteredProps) {
    const { classes } = useStyles();
    const items = links.map((link) => (
        <Anchor
            component={Link}
            to={link.link}
            key={link.label}
            className={classes.link}
        >
            {link.label}
        </Anchor>
    ));

    return (
        <div className={classes.footer}>
            <div className={classes.inner}>
                <Anchor component={Link} to={`/`}>
                    <Text
                        component="span"
                        align="center"
                        variant="gradient"
                        gradient={{ from: "indigo", to: "cyan", deg: 45 }}
                        size="xl"
                        weight={800}
                    >
                        PriceToRent
                    </Text>
                </Anchor>
                <Group className={classes.links}>{items}</Group>

                <Group spacing="xs" position="right" noWrap>
                    <ActionIcon size="lg" variant="default" radius="xl"
                        component="a"
                        href={"https://twitter.com/PriceToRent"}
                        target="_blank"
                        rel="noopener noreferrer">
                        <IconBrandTwitter size="1.05rem" stroke={1.5} />
                    </ActionIcon>
                </Group>

            </div>
            <Container className={classes.afterFooter}>
                <Text color="dimmed" size="sm">
                    © {new Date().getFullYear()} PriceToRent.com. All rights reserved.
                </Text>
            </Container>
        </div>
    );
}

const footerLinks = {
    "links": [
        navLinks["Home"],
        navLinks["Dashboard"],
        navLinks["Privacy"],
        navLinks["Terms"],
    ]
};

export function FooterPopulated() {
    return <FooterCentered links={footerLinks.links} />;
}