import DashboardIntro from "../Dashboard/Intro";
import StreamlitApp from "../Dashboard/StreamlitApp";
import Usage from "../Dashboard/Usage";

const Dashboard = () => {

    return (
        <div>
            <DashboardIntro />
            <StreamlitApp />
            <Usage />
        </div>
    )
};
export default Dashboard;
