export const navLinks = {
    "Home": {
        link: "/",
        label: "Home",
    },
    "Dashboard": {
        link: "/dashboard",
        label: "Dashboard",
    },
    "Privacy": {
        link: "/privacy",
        label: "Privacy Policy",
    },
    "Terms": {
        link: "/tos",
        label: "Terms of Service",
    },
}