import { AppShell, MantineProvider } from '@mantine/core';
import { HeaderPopulated } from './components/Navigation/Header';
import { FooterPopulated } from './components/Navigation/Footer';
import { Routes, Route } from "react-router-dom";

import Home from './components/Pages/Home';
import Privacy from './components/Pages/Privacy';
import TermsofUse from './components/Pages/TermsofUse';
import Dashboard from './components/Pages/Dashboard';

export default function App() {
  return (
    <MantineProvider
      theme={{ colorScheme: "dark" }}
      withGlobalStyles
      withNormalizeCSS
    >
      <AppShell header={<HeaderPopulated />} footer={<FooterPopulated />}>
        <Routes>
          <Route path="*" element={<Home />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="privacy" element={<Privacy />} />
          <Route path="tos" element={<TermsofUse />} />
        </Routes>
      </AppShell>
    </MantineProvider>
  );
}