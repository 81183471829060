import {
    Text,
    Container,
    ThemeIcon,
    Title,
    SimpleGrid,
    createStyles,
    rem,
    Image
} from '@mantine/core';

const useStyles = createStyles((theme) => ({
    wrapper: {
        paddingTop: rem(80),
        paddingBottom: rem(50),
    },

    item: {
        display: 'flex',
    },

    itemIcon: {
        padding: theme.spacing.xs,
        marginRight: theme.spacing.md,
    },

    itemTitle: {
        marginBottom: `calc(${theme.spacing.xs} / 2)`,
    },

    supTitle: {
        textAlign: 'center',
        textTransform: 'uppercase',
        fontWeight: 800,
        fontSize: theme.fontSizes.sm,
        color: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).color,
        letterSpacing: rem(0.5),
    },

    title: {
        lineHeight: 1,
        textAlign: 'center',
        marginTop: theme.spacing.xl,
    },

    description: {
        textAlign: 'center',
        marginTop: theme.spacing.xs,
    },

    highlight: {
        backgroundColor: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).background,
        padding: rem(5),
        paddingTop: 0,
        borderRadius: theme.radius.sm,
        display: 'inline-block',
        color: theme.colorScheme === 'dark' ? theme.white : 'inherit',
    },
}));

interface FeatureImage {
    image: string;
    title: React.ReactNode;
    description: React.ReactNode;
}

interface FeaturesImagesProps {
    description: React.ReactNode;
    data: FeatureImage[];
}

export function FeaturesImages({ description, data }: FeaturesImagesProps) {
    const { classes } = useStyles();

    const items = data.map((item) => (
        <div className={classes.item} key={item.image}>
            <ThemeIcon variant="light" className={classes.itemIcon} size={60} radius="md">
                <Image src={item.image} alt={item.title + ' icon'} />
            </ThemeIcon>

            <div>
                <Text fw={700} fz="lg" className={classes.itemTitle}>
                    {item.title}
                </Text>
                <Text c="dimmed">{item.description}</Text>
            </div>
        </div>
    ));

    return (
        <Container size={700} className={classes.wrapper}>

            <Title className={classes.title} order={2}>
                PriceToRent is <span className={classes.highlight}>not</span> just for real estate investors!
            </Title>

            <Container size={660} p={0}>
                <Text color="dimmed" className={classes.description}>
                    {description}
                </Text>
            </Container>

            <SimpleGrid
                cols={2}
                spacing={50}
                breakpoints={[{ maxWidth: 550, cols: 1, spacing: 40 }]}
                style={{ marginTop: 30 }}
            >
                {items}
            </SimpleGrid>
        </Container>
    );
}

const data2 = {
    "description": "PriceToRent is for everyone and anyone. Here's who we think could benefit the most from our platform and data crunching.",
    "data": [
        {
            "image": "investor.svg",
            "title": "Real Estate Investors",
            "description": "PriceToRent can help you find your next cash flowing investment property."
        },
        {
            "image": "home_owner.svg",
            "title": "Aspiring Home Owners",
            "description": "PriceToRent can help you find your dream home."
        },
        {
            "image": "agent.svg",
            "title": "Real Estate Agents",
            "description": "PriceToRent can simplify your work and help serve your clients."
        },
        {
            "image": "flipper.svg",
            "title": "Home Flippers",
            "description": "PriceToRent can help you find booming locations with potential."
        }
    ]
}

export function WhoIsForPopulated() {
    return <FeaturesImages data={data2.data} description={data2.description} />;
}