import {
    createStyles,
    Title,
    Text,
    Card,
    SimpleGrid,
    Container,
    rem,
} from '@mantine/core';
import { IconClipboard, IconSearch, IconGlobe } from '@tabler/icons-react';

const mockdata = [
    {
        title: 'Location Insights',
        description:
            "We provide in-depth data analysis to help you identify the most lucrative locations for real estate investment or homeownership, based on your goals and preferences.",
        icon: IconSearch,
    },
    {
        title: 'Property Assessment',
        description:
            'Quickly assess property values and rental potential in your chosen areas, ensuring you make informed decisions that align with your financial objectives.',
        icon: IconClipboard,
    },
    {
        title: 'Nationwide Data',
        description:
            "Our platform compiles comprehensive data from all over the United States, giving you a nationwide perspective on the real estate market to aid in your decision-making process.",
        icon: IconGlobe,
    },
];

const useStyles = createStyles((theme) => ({
    title: {
        fontSize: rem(34),
        fontWeight: 900,

        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(24),
        },
    },

    description: {
        maxWidth: 600,
        margin: 'auto',

        '&::after': {
            content: '""',
            display: 'block',
            backgroundColor: theme.fn.primaryColor(),
            width: rem(45),
            height: rem(2),
            marginTop: theme.spacing.sm,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },

    card: {
        border: `${rem(1)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1]
            }`,
    },

    cardTitle: {
        '&::after': {
            content: '""',
            display: 'block',
            backgroundColor: theme.fn.primaryColor(),
            width: rem(45),
            height: rem(2),
            marginTop: theme.spacing.sm,
        },
    },
}));

export function FeaturesCards() {
    const { classes, theme } = useStyles();
    const features = mockdata.map((feature) => (
        <Card key={feature.title} shadow="md" radius="md" className={classes.card} padding="xl">
            <feature.icon size={rem(50)} stroke={2} color={theme.fn.primaryColor()} />
            <Text fz="lg" fw={500} className={classes.cardTitle} mt="md">
                {feature.title}
            </Text>
            <Text fz="sm" c="dimmed" mt="sm">
                {feature.description}
            </Text>
        </Card>
    ));

    return (
        <Container size="lg" py="xl">
            <Title order={2} className={classes.title} ta="center" mt="sm">
                How Can It Help You?
            </Title>

            <Text c="dimmed" className={classes.description} ta="center" mt="md">
                Whether you're searching for your dream home or next investment property, we've got you covered.
                Maybe you just want to browse, that's okay too!
            </Text>

            <SimpleGrid cols={3} spacing="xl" mt={50} breakpoints={[{ maxWidth: 'md', cols: 1 }]}>
                {features}
            </SimpleGrid>
        </Container>
    );
}