import { FaqSimple } from "../Home/Faq";
import { FeaturesCards } from "../Home/HowCanItHelpYou";
import { WhoIsForPopulated } from "../Home/WhoIsItFor";
import { HeroText } from "../Home/Hero";
// import { InvestorSearchFeatures } from "../Home/InvestorSearch";
// import { HomeSearchFeatures } from "../Home/HomeSearch";
import { ScrollToLoc } from "../Navigation/ScrollToLoc";


const Home = () => {

    ScrollToLoc();

    return (
        <div>
            <HeroText />
            <FeaturesCards />
            {/* <InvestorSearchFeatures /> */}
            {/* <HomeSearchFeatures /> */}
            <WhoIsForPopulated />
            <FaqSimple />
        </div>
    );
};

export default Home;