import { Container, Title, createStyles, rem, Text, Anchor } from "@mantine/core";
import { ScrollToLoc } from "../Navigation/ScrollToLoc";
import { Link } from "react-router-dom";

const useStyles = createStyles((theme) => ({
    wrapper: {
        position: 'relative',
        paddingTop: rem(120),
        paddingBottom: rem(120),

        [theme.fn.smallerThan('sm')]: {
            paddingTop: rem(80),
            paddingBottom: rem(60),
        },
    },

    inner: {
        position: 'relative',
        zIndex: 1,
    },


    title: {
        textAlign: 'center',
        fontWeight: 800,
        fontSize: rem(40),
        letterSpacing: -1,
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        marginBottom: theme.spacing.xs,
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,

        [theme.fn.smallerThan('xs')]: {
            fontSize: rem(28),
            textAlign: 'left',
        },
    },

    highlight: {
        color: theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6],
    },

    description: {
        textAlign: 'center',

        [theme.fn.smallerThan('xs')]: {
            textAlign: 'left',
            fontSize: theme.fontSizes.md,
        },
    },
}));

const DashboardIntro = () => {
    const { classes } = useStyles();
    ScrollToLoc();

    return (
        <Container className={classes.wrapper}>

            <div className={classes.inner}>
                <Anchor component={Link} to={`#dashboard`} style={{ textDecoration: 'none' }} color="default">
                    <Title className={classes.title}>
                        The PriceToRent{' '}
                        <Text component="span" className={classes.highlight} inherit>
                            Dashboard
                        </Text>{' '}
                    </Title>
                </Anchor>
                <Container p={0} size={600}>
                    <Text size="lg" color="dimmed" className={classes.description}>
                        Specify your location and we'll do the hard work for you.
                    </Text>
                </Container>
            </div>
        </Container>
    );
};
export default DashboardIntro;
