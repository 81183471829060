import { Container, Title, Accordion, createStyles, rem } from '@mantine/core';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

const useStyles = createStyles((theme) => ({
    wrapper: {
        paddingTop: `calc(${theme.spacing.xl} * 2)`,
        paddingBottom: `calc(${theme.spacing.xl} * 2)`,
        minHeight: 650,
    },

    title: {
        marginTop: rem(120),
        marginBottom: `calc(${theme.spacing.xl} * 1.5)`,
    },

    item: {
        borderRadius: theme.radius.md,
        marginBottom: theme.spacing.lg,
        border: `${rem(1)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
            }`,
    },
}));

export default function Usage() {
    const { classes } = useStyles();

    const scrollToFAQ = () => {
        const faqSection = document.getElementById('faq');
        faqSection?.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        const hash = window.location.hash;
        if (hash === '#faq') {
            scrollToFAQ();
        }
    }, []);
    return (
        <Container size="sm" className={classes.wrapper}>
            <Title align="center" className={classes.title} id="faq">
                Dashboard FAQ
            </Title>

            <Accordion variant="separated">

                <Accordion.Item className={classes.item} value="data-q">
                    <Accordion.Control>How often is data updated?</Accordion.Control>
                    <Accordion.Panel>
                        Data is updated monthly and aggregrated from multiple sources.
                    </Accordion.Panel>
                </Accordion.Item>

                <Accordion.Item className={classes.item} value="ratio-q">
                    <Accordion.Control>How is the PriceToRent ratio calculated?</Accordion.Control>
                    <Accordion.Panel>
                        This is calculated by dividing the home price by the annual rent.
                        A ratio under 15 means that buying is more favorable.
                        A ratio over 16 means that renting is more favorable.
                    </Accordion.Panel>
                </Accordion.Item>

                <Accordion.Item className={classes.item} value="insights-q">
                    <Accordion.Control>I want more insights!</Accordion.Control>
                    <Accordion.Panel>
                        We have plans to add further insights like being able to view the PriceToRent ratio
                        at much more granular levels. Maybe you'd like to see where exactly in a location
                        is the best place to buy. Maybe you'd like to see some more trends. Maybe additional
                        info like neighbourhood insights as well.

                        If you're interested,{" "}
                        <Link to={"https://twitter.com/PriceToRent"}>
                            reach out to us on Twitter!
                        </Link>{" "}
                        We'd love to hear how we can better
                        service your needs.
                    </Accordion.Panel>
                </Accordion.Item>

            </Accordion>
        </Container >
    );
}
