import { useScrollIntoView } from '@mantine/hooks';

export function ScrollToLoc<T extends HTMLElement>() {
    const { scrollIntoView } = useScrollIntoView<T>({
        offset: 0,
    });

    scrollIntoView({
        alignment: 'center',
    });
}